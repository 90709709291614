<template>
  <div class="review">
    <van-nav-bar
      title="打印预览"
      left-text="返回"
      @click-left="goBack"
      left-arrow
    >
      <template #right>
        <span class="setting" @click="setting"
          ><van-icon name="setting-o" />设置</span
        >
        <span class="print" @click="printFile"
          ><van-icon name="brush-o" />打印</span
        >
      </template>
    </van-nav-bar>

    <iframe
      class="file"
      :src="
        fileInfo.ext == 'pdf'
          ? prefix(fileInfo.filePath)
          : prefixFile(prefix(fileInfo.filePath))
      "
      frameborder="0"
    ></iframe>

    <div class="form" v-show="showSetting">
      <van-form>
        <van-field name="stepper" label="打印份数">
          <template #input>
            <van-stepper v-model="step" />
          </template>
        </van-field>
        <van-field
          v-model="printer"
          readonly
          label="打印机"
          placeholder="打印机"
          @click-input="showPrinter = true"
        />
        <van-field name="radio" label="打印类型">
          <template #input>
            <van-radio-group v-model="checkedPrintType" direction="horizontal">
              <van-radio :name="1">双面打印</van-radio>
              <van-radio :name="2">单面打印</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="radio" label="打印颜色">
          <template #input>
            <van-radio-group v-model="checkedPrintColor" direction="horizontal">
              <van-radio :name="1">黑白</van-radio>
              <van-radio :name="2">彩色</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="paper"
          label="打印纸张"
          placeholder="A4"
          readonly
          @click-input="showPrintPaper = true"
        ></van-field>
        <van-field label="打印方向" readonly>
          <template #input>
            <van-radio-group v-model="checkedPrintDir" direction="horizontal">
              <van-radio :name="1">
                <van-image
                  width="5vw"
                  height="5vw"
                  :src="require('@/assets/images/paiban-shu.png')"
                />
              </van-radio>
              <van-radio :name="2">
                <van-image
                  width="5vw"
                  height="5vw"
                  :src="require('@/assets/images/paiban-heng.png')"
                />
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-form>
    </div>

    <van-popup
      v-model:show="showPrinter"
      :style="{ height: '50%' }"
      position="bottom"
    >
      <van-picker
        :columns="columns"
        @confirm="onConfirmPrinter"
        @cancel="showPrinter = false"
      />
    </van-popup>
    <van-popup
      v-model:show="showPrintPaper"
      :style="{ height: '50%' }"
      position="bottom"
    >
      <van-picker
        :columns="columnsPaper"
        @confirm="onConfirmPrinterPaPer"
        @cancel="showPrintPaper = false"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { prefix, prefixFile } from "@/common/js/utils.js";
import { apiCreatePrintTask } from "@/service/teacher1.4.js";

const router = useRouter();
const fileInfo = JSON.parse(localStorage.getItem("file"));
const settingInfo = JSON.parse(localStorage.getItem("setting"));

let showPrinter = ref(false);
let showPrintPaper = ref(false);
let showSetting = ref(false);

const columns = ref([{ text: "致远云打印", value: 1 }]);
let columnsPaper = ref([
  { text: "A4", value: 9 },
  { text: "A5", value: 11 },
]);

let step = ref(1);
let printer = ref("");
const onConfirmPrinter = (e) => {
  printer.value = e.text;
  showPrinter.value = false;
};

let checkedPrintType = ref(1);
let checkedPrintColor = ref(1);
// 纸张
let paper = ref("A4");
let paperType = ref(0);
const onConfirmPrinterPaPer = (e) => {
  paper.value = e.text;
  paperType.value = e.value;
  showPrintPaper.value = false;
};

// 打印方向
let checkedPrintDir = ref(1);

// 设置
const setting = () => {
  showSetting.value = !showSetting.value;
};

// 打印
const printFile = async () => {
  let file = prefix(fileInfo.filePath);
  let data =
    +localStorage.getItem("type") == 1 || +localStorage.getItem("type") == 3
      ? {
          caseName: settingInfo.title,
          to:
            settingInfo.createTo == 1
              ? "grade"
              : settingInfo.createTo == 2
              ? "class"
              : "student",
          gradeId: settingInfo.gradeId,
          classIds: settingInfo.classIds.join(","),
          studentIds: settingInfo.studentIds.join(","),
          subjectId: settingInfo.subjectId,
          booksId: settingInfo.bookId,
          booksListId: settingInfo.treeId,
        }
      : {};
  let printSetting =
    +localStorage.getItem("type") == 1 || +localStorage.getItem("type") == 2
      ? {
          dmPaperSize: paperType.value ? paperType.value : 9,
          dmOrientation: checkedPrintDir.value,
          dmCopies: step.value,
          dmDuplex: checkedPrintType.value,
        }
      : {};
  let res = await apiCreatePrintTask({
    file: file,
    type: +localStorage.getItem("type"),
    case_info: data,
    print_setting: printSetting,
  });
  if (res) {
    router.push({ path: "/teacher/printFile/creating" });
    localStorage.setItem("active", 2);
    localStorage.setItem("taskId", res.data.taskId);
  }
};

const goBack = () => {
  localStorage.setItem("active", 0);
  router.go(-1);
};
</script>

<style lang="less" scoped>
.setting {
  margin-right: 20px;
}
.print,
.setting {
  border: 1px solid #18a4e0;
  color: #18a4e0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  border-radius: 4px;
}

.file {
  width: 100vw;
  height: 95vh;
  margin: auto;
}
.form {
  width: 50vw;
  position: absolute;
  right: 10px;
  top: 45px;

  // z-index: ;
  border: 1px solid #eee;
}
</style>
